<template>
	<div class="section" style="padding: 0!important;">
		<div class="card">
			<div class="card-header has-background-black">
				<div class="card-header-title has-text-white">
					Maldo Çekim Paneli Ayarları
				</div>
			</div>
			<div class="card-content">
				<b-field label="Username">
					<b-input
						size="is-small"
						v-model="form.username"
						placeholder="Username"
						name="username"
						required />
				</b-field>
				<b-field label="Password">
					<b-input
						size="is-small"
						v-model="form.password"
						name="password"
						type="password"
						required />
				</b-field>
				<b-field label="Maksimum Limit">
					<b-input
						size="is-small"
						v-model="form.withdrawMaxLimit"
						placeholder="Maksimum Limit"
						name="withdrawMaxLimit"
						required />
				</b-field>
				<b-field label="Durum">
					<b-switch size="is-small" v-model="form.withdrawStatus">{{
						form.withdrawStatus === true ? "Aktif" : "Pasif"
					}}</b-switch>
				</b-field>
				<b-field>
					<b-button type="is-success" size="is-small" @click="save"
						>Kaydet</b-button
					>
				</b-field>
			</div>
		</div>
	</div>
</template>
<script>
	import { MaldoService as Service } from "@/services"
	export default {
		name: "MaldoWithdrawPanel",
		data() {
			return {
				form: {
					withdrawStatus: false,
					withdrawMaxLimit: 0,
					username: 'trustpapara2',
					password: 'eXqeaLH62EV2MsQ'
				},
			}
		},
		mounted() {
			Service.setting().then((res) => (this.form = res.data))
		},
		methods: {
			save() {
				Service.settingUpdate(this.form).then((res) => {
					this.$buefy.toast.open({
						message: "Maldo Panel Ayarları Güncellendi",
						type: "is-success",
					})
					this.$emit('close')
				})
			},
		},
	}
</script>
